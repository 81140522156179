<template>
  <v-menu
    :ref="refName"
    v-model="isModalVisible"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="viewDate"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        outlined
        hide-details
        readonly
        v-bind="attrs"
        clearable
        v-on="on"
        @click:clear="handleClear"
      />
    </template>

    <v-date-picker
      :value="date"
      :show-current="false"
      :range="true"
      no-title
      scrollable
      @input="handleMenuUpdate"
    />
  </v-menu>
</template>

<script>
import dateToLocalString from "@/helpers/dateToLocalString";

export default {
  name: "TimeDataPicker",

  props: {
    refName: {
      type: String,
      required: true,
    },
    date: {
      type: Array,
      default: () => null,
    },
    label: {
      type: String,
      default: () => "Date",
    },
  },

  data() {
    return {
      isModalVisible: false,
    };
  },

  computed: {
    viewDate() {
      if (!this.date) return null;
      return this.date.map((d) => dateToLocalString(d, false)).join(" - ");
    },
  },

  methods: {
    handleMenuUpdate(value) {
      const preparedDate = this.prepareDateForUpdate(value);
      this.$emit("change", preparedDate);
    },
    handleClear() {
      this.$emit("change", null);
    },
    prepareDateForUpdate(value) {
      const [first, second = null] = value;
      if (!second) return [first];
      if (new Date(first).getTime() > new Date(second).getTime()) {
        return [second, `${first} 24:00:00`];
      }
      return [first, `${second} 24:00:00`];
    },
  },
};
</script>
